import axios from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { replace } from "connected-react-router";
import apiCall from "../../utils/ApiService";
import { getCookie } from "../../utils/cookies";
import { VERIFY_PLATFORM, VERIFY_TIKTOK_PLATFORM } from "./constants";
import AlertMessage from "../../components/AlertMessage";
import { verifyPlatformFailed, verifyTiktokPlatformFailed } from "./actions";

export function* verifyPlatformWorker({ params }) {
    try {
        yield call(apiCall, "POST", "/platforms", {
            data: params,
        });

        AlertMessage("success", "Success!");
        yield put(replace("/home"));
    } catch (err) {
        yield put(verifyPlatformFailed(err));
        const { response } = err;
        AlertMessage("error", response.data.message);
    }
}

export function* verifyTiktokPlatformWorker({ params }) {
    const token = `Bearer ${getCookie("accessToken")}`;
    try {
        yield axios.post(
            `${process.env.REACT_APP_TIKTOK_URL}/platforms`,
            {
                data: params,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            },
        );
        AlertMessage("success", "Success!");
        yield put(replace("/home"));
    } catch (err) {
        yield put(verifyTiktokPlatformFailed(err));
        const { response } = err;
        AlertMessage("error", response.data.message || response.data);
    }
}

export default function* platformWatcher() {
    yield takeLatest(VERIFY_TIKTOK_PLATFORM, verifyTiktokPlatformWorker);
    yield takeLatest(VERIFY_PLATFORM, verifyPlatformWorker);
}

import { takeLatest, call, put, select } from "redux-saga/effects";
import { List, Map } from "immutable";
import _ from "lodash";
import qs from "query-string";
import apiCall from "../../utils/ApiService";
import AlertMessage from "../../components/AlertMessage";
import { GET_PLATFORMS, HANDLE_UPDATE_SHOP, HANDLE_SELECT_FETCH } from "./constants";
import {
    getPlatformsSuccess,
    getPlatformsFailed,
    toggleIsTableLoading,
    getFilteredPlatforms,
    clearSelectedShops,
    getPlatforms,
} from "./actions";
import { makeSelectTableControls } from "./selectors";

const platformsUrl = "/platforms";

export function* getPlatformsWorker({ tableControls }) {
    const curTableControls = yield select(makeSelectTableControls);

    const mergeTableControls = _.pickBy({
        ...curTableControls,
        ...tableControls,
    });
    const serializedTableControls = qs.stringify(mergeTableControls);

    yield put(toggleIsTableLoading());
    try {
        const { data, current_page, total_entries } = yield call(
            apiCall,
            "GET",
            `${platformsUrl}?${serializedTableControls}`,
        );
        yield put(getPlatformsSuccess(List(data), Map({ ...mergeTableControls, current_page }), total_entries));

        yield put(getFilteredPlatforms(List(data)));
    } catch (err) {
        yield put(getPlatformsFailed(err));
        const { response } = err;
        AlertMessage("error", response.data.message);
    }
    yield put(toggleIsTableLoading());
}

export function* handleUpdateShopWorker({ shop }) {
    try {
        yield call(apiCall, "PUT", platformsUrl, {
            data: {
                unique_platform_id: shop.unique_platform_id,
                request: {
                    active_dates: shop.active_dates,
                    active: shop.active,
                    name: shop.name,
                    shop_name: shop.shop_name,
                    shop_url: shop.shop_url,
                },
            },
        });
        AlertMessage("success", "Shop was updated successfully.");
    } catch (error) {
        const { response } = error;
        AlertMessage("error", response.data.message);
        console.error(error);
    }
}

export function* handleSelectFetchWorker() {
    try {
        yield put(clearSelectedShops());
        yield put(getPlatforms());
    } catch (error) {
        AlertMessage("error", "Platform selection failed.");
    }
}

export default function* shopsWatcher() {
    yield takeLatest(GET_PLATFORMS, getPlatformsWorker);
    yield takeLatest(HANDLE_UPDATE_SHOP, handleUpdateShopWorker);
    yield takeLatest(HANDLE_SELECT_FETCH, handleSelectFetchWorker);
}

import { takeLatest, put, call, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { Map } from "immutable";
import apiCall from "../../utils/ApiService";
import AlertMessage from "../../components/AlertMessage";
import { loginSuccess, logoutSuccess, clearLocalStorage } from "./actions";
import { LOGIN, LOGOUT, CLEAR_LOCALSTORAGE } from "./constants";
import { clearAllOrders, toggleIsFirstLoad } from "../Orders/actions";
import { clearEffie } from "../Effie/actions";
import { clearRemittance } from "../Remittance/actions";
import { setCookie, delCookie } from "../../utils/cookies";

export function* loginWorker({ ...creds }) {
    try {
        const defaultDevice = {
            platform: "Linux 0.0.0",
            device: "Web",
            token: "715d5e2b22059b6f2feb713640068bf6",
        };

        const { data } = yield call(apiCall, "POST", "/login", {
            data: {
                username: creds.username,
                password: creds.password,
                device: defaultDevice,
            },
        });

        yield setCookie("accessToken", data.accessToken, 7);

        yield localStorage.setItem("currentUser", JSON.stringify(data.user));
        yield all([put(loginSuccess(Map(data.user))), put(replace("/home"))]);
    } catch ({ response }) {
        AlertMessage("error", response.data.message);
    }
}

export function* clearLocalStorageWorker() {
    yield localStorage.removeItem("currentUser");

    yield localStorage.clear();
}

export function* logoutWorker() {
    try {
        yield delCookie("accessToken");
        yield put(clearLocalStorage());
        yield put(clearAllOrders());
        yield put(logoutSuccess());
        yield put(toggleIsFirstLoad());
        yield put(clearEffie());
        yield put(clearRemittance());
        yield put(replace("/login"));
    } catch (err) {
        AlertMessage("error", "Logout failed!");
    }
}

export default function* authWatcher() {
    yield takeLatest(LOGIN, loginWorker);
    yield takeLatest(LOGOUT, logoutWorker);
    yield takeLatest(CLEAR_LOCALSTORAGE, clearLocalStorageWorker);
}

import React from "react";
import Loadable from "react-loadable";

// put your routes inside this array.
const routes = [
    {
        name: "Landing",
        path: "/",
        restricted: true,
        authMode: "public",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Landing"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Login",
        path: "/login",
        restricted: true,
        authMode: "public",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Login"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Register",
        path: "/register",
        restricted: true,
        authMode: "public",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Register"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Home",
        path: "/home",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Home"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Account",
        path: "/accounts",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Account"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Account Form",
        path: "/accounts/create",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/AccountForm"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Files",
        path: "/files",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Files"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Shopify",
        path: "/shopify",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Shopify"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Lazada",
        path: "/platforms",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Platforms"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Shopee Callback",
        path: "/shopee/callback",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Platforms"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Shopee Callback",
        path: "/shopee-v2/callback",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Platforms"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Lazada Callback",
        path: "/lazada/callback",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Platforms"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Tiktok Callback",
        path: "/tiktok/callback",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Platforms"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Effie",
        path: "/purchase-orders",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Effie"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Orders",
        path: "/orders",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Orders"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "CAMS Report",
        path: "/campaign-tracker-report",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/ReportCampaignTracker"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Collection Receipt",
        path: "/collection-receipt",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/CollectionReceipt"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Shops",
        path: "/shops",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/ShopManager"),
            loading: () => <div>Loading...</div>,
        }),
    },
    {
        name: "Reports",
        path: "/reports",
        authMode: "private",
        exact: true,
        component: Loadable({
            loader: () => import("./containers/Reports"),
            loading: () => <div>Loading...</div>,
        }),
    },
];

export default routes;
